import React from "react";
import { Link } from "gatsby";
import { HeroContentWrap, Hero, HeroWrapper, BreadCrumbFlex, ImgWrap, TextSide, Eyebrow, Heading, SubSection, Group, Text, HeadingWrap } from "./styles/PodcastDetailHero.styled";
import OptimizedImage from "../../utils/optimizedImage";
import { HeadIcon, Calendar, Spotify, ApplePodcast, Amazon } from "../../utils/IconsList";
import { publishDateFormat } from "../../utils/text";
import useScreenResponsive from "../../utils/screenResponsive";

const PodcastHero = ({ title, epNum, publishDate, speakers, featuredImage }) => {
  const date = publishDate && publishDateFormat(publishDate)
  const isTablet = useScreenResponsive(992)

  return (
    <HeroWrapper>
      <Hero>
        <BreadCrumbFlex>
          <Link href="/">
            <span>Home</span>
          </Link>
          <img
            src="/icons/chevron-right-white.svg"
            alt="Breadcrumb Chevron"
          />
          <Link href="/podcast/">
            <span>Podcasts</span>
          </Link>
          <img
            src="/icons/chevron-right-white.svg"
            alt="Breadcrumb Chevron"
          />
          <span>Podcast Episode {epNum && epNum}</span>
        </BreadCrumbFlex>
        <HeroContentWrap>
          <TextSide>
            <HeadingWrap>
              {epNum &&
                <Eyebrow>Podcast Episode {epNum}</Eyebrow>
              }
              <Heading>{title}</Heading>
            </HeadingWrap>
            <SubSection>
              {speakers[0] && 
                <Group>
                  <HeadIcon />
                  <Text>Guest: {speakers[0]?.fullName}</Text>
                </Group>
              }
              {publishDate && 
                <Group>
                  <Calendar />
                  <Text>Published on {date}</Text>
                </Group>
              }
            </SubSection>
            {!isTablet &&
              <SubSection>
                <Text bold>Listen on:</Text>
                <a href="https://open.spotify.com/show/7AVV8GvtDdpOzeS2mL4Iw6?si=133115f21d844298" target="_blank" rel="noreferrer">
                  <Spotify />
                </a>
                <a href="https://music.amazon.com/podcasts/c3758a01-d1ea-4474-837e-4c59e0c0ffb4/the-workgrid" target="_blank" rel="noreferrer">
                  <Amazon />
                </a>
                <a href="https://podcasts.apple.com/us/podcast/the-workgrid/id1694988359" target="_blank" rel="noreferrer">
                  <ApplePodcast />
                </a>
              </SubSection>
            }
          </TextSide>
          {featuredImage && 
            <ImgWrap>
              <OptimizedImage image={featuredImage?.gatsbyImageData} src={featuredImage?.src} alt={featuredImage?.alt} />
            </ImgWrap>
          }
          {isTablet &&
            <SubSection blue>
              <Text bold>Listen on:</Text>
              <a href="https://open.spotify.com/show/7AVV8GvtDdpOzeS2mL4Iw6?si=133115f21d844298" target="_blank" rel="noreferrer">
                <Spotify />
              </a>
              <a href="https://music.amazon.com/podcasts/c3758a01-d1ea-4474-837e-4c59e0c0ffb4/the-workgrid" target="_blank" rel="noreferrer">
                <Amazon />
              </a>
              <a href="https://podcasts.apple.com/us/podcast/the-workgrid/id1694988359" target="_blank" rel="noreferrer">
                <ApplePodcast />
              </a>
            </SubSection>
          }
        </HeroContentWrap>
      </Hero>
    </HeroWrapper>
  )
}

export default PodcastHero
